interface NotificationOptions {
  icon?: string;
}

export interface Notification {
  headline: string;
  message?: string;
  type: "success" | "error" | "warning" | "info";
  icon: string;
}

export function createSuccessNotification(
  headline: string,
  message?: string,
  options: NotificationOptions = {}
): Notification {
  return {
    headline,
    message,
    type: "success",
    icon: options.icon ?? "check-circle",
  };
}

export function createWarningNotification(
  headline: string,
  message?: string,
  options: NotificationOptions = {}
): Notification {
  return {
    headline,
    message,
    type: "warning",
    icon: options.icon ?? "exclamation-triangle",
  };
}

export function createErrorNotification(
  headline: string,
  message?: string,
  options: NotificationOptions = {}
): Notification {
  return {
    headline,
    message,
    type: "error",
    icon: options.icon ?? "exclamation-triangle",
  };
}

export function createInfoNotification(
  headline: string,
  message?: string,
  options: NotificationOptions = {}
): Notification {
  return {
    headline,
    message,
    type: "info",
    icon: options.icon ?? "info-circle",
  };
}
