


















import { Component, Prop, Vue } from "vue-property-decorator";
import AppInput from "@/components/core/AppInput.vue";
import AppInputGroup from "@/components/core/AppInputGroup.vue";

@Component({ components: { AppInput, AppInputGroup } })
export default class AppCountdown extends Vue {
  @Prop({ type: String, required: false })
  readonly value!: string;

  @Prop({ type: String, required: true })
  readonly id!: string;

  @Prop({ type: Boolean, default: false })
  readonly required!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ type: String, required: false })
  readonly autocomplete!: string;

  @Prop({ type: Number, required: true })
  readonly maxChars!: number;

  get charCount(): number {
    if (!this.value) return 0;
    return this.value.length;
  }

  get availableChars(): number {
    if (!this.maxChars) return 0;
    else if (!this.charCount) return this.maxChars;
    return this.maxChars - this.charCount;
  }
}
